<template>
    <div class="pd20x flex1">
        <addSwiper :visible="visible" @close="visible = false" :data="currentItem" @update="getData"></addSwiper>
        <div class="h100pct ofA">
             <div class="filter pd20x mb10x">
                <div>轮播图数目（{{totle}}}条</div>
                <div>
                    <a-button type="primary" @click="()=>{ currentItem = null ; visible = true }">添加</a-button>
                </div>
            </div>
            <a-spin :spinning="loading">
                <div class="dragTable">
                    <div class="dragHeader">
                        <div class="iconTh"></div>
                        <div class="flex1">轮播图</div>
                        <div class="flex1">链接</div>
                        <div class="flex1">启用状态</div>
                        <div class="flex1">设为主页</div>
                        <div class="operate">操作</div>
                    </div>
                    <draggable handle=".dragIcon" :list="result" v-if="result.length" @change="log">
                        <div class="dragBody" v-for="(item, index) in result" :key="index">
                            <div class="iconTh">
                                <i class="dragIcon"></i>
                            </div>
                            <div class="flex1">
                                <div class="swiperImg">
                                    <img :src="item.img_url" alt="">
                                </div>
                            </div>
                            <div class="flex1">{{item.url}}</div>
                            <div class="flex1"><a-switch default-checked /></div>
                            <div class="flex1"><a-switch default-checked /></div>
                            <div class="operate">
                                <i class="z-icon edit" @click="()=>{currentItem = item ; visible = true}"></i>
                                <i class="z-icon delete" @click="deleteItem(item.id)"></i>
                            </div>
                        </div>
                    </draggable>
                    <div v-if="!result.length && !loading" style="padding:20px 0;text-align:center;background:#fff">
                        暂无数据
                    </div>
                </div>
            </a-spin>
        </div>
    </div>
</template>
<script>
import * as http from '@/libs/examapi'
import addSwiper from '@/components/exam/addSwiper'
export default {
    name: 'swiperLIst',
    components: { addSwiper },
    data(){
        return{
            result: [],
            visible: false,
            loading: true,
            currentItem: null,
            totle: 0
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.getData()
        })
    },
    methods:{
        getData(){
            this.loading = true
            http.get_banner().then(res=>{
                this.loading = false
                this.loading = false
                this.result = res
                this.totle = res.length
            }).catch(error=>{
                this.loading = false
                console.log(error)
            })
        },
        handleChange(){

        },
        onSelectChange(){

        },
        addItem(){
            this.visible = true;
            this.currentItem = null
        },
        deleteItem(id){
            let _this = this
             this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                    http.del_banner({id}).then(res=>{
                        _this.getData()
                    }).catch(error=>{
                        console.log(error)
                    })
                },
            });
        },
        log(){
            let result = { ids:this.result.map(el=>el.id).join(',')}
            http.order_banner(result).then(res=>{
            }).catch(error=>{
                this.$message.error('拖拽失败，请重新操作')
                this.getData()
                console.log(error)
            })
        },
        hideModal(){

        }
    }
}
</script>
<style lang="less" scoped>

</style>