<template>
     <a-modal
        :title="title"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="$emit('close')"
        @ok="handleSubmit"
        destroyOnClose
    >
      <a-form
        :form="form"
        v-bind="formItemLayout"
        v-if="visible"
    >
        <a-form-item label="上传图片">
            <a-upload
                name="avatar"
                list-type="picture-card"
                :show-upload-list="false"
                action=""
                :before-upload="beforeUpload"
                @change="handleChange"
                :customRequest="customRequest"
            >
                <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" style="font-size:30px;color:#dcdcdc"/>
                </div>
            </a-upload>
            <span>建议尺寸为123*123，大小不超过10M,格式为jpg</span>
        </a-form-item>
        <a-form-item label="链接">
            <a-input v-model="url" placeholder="请输入链接" />
        </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
import { uploadImg } from '@/libs/api'
import * as http from '@/libs/examapi'
export default {
    name: 'addSwiper',
    props: [ 'visible', 'data' ],
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    watch:{
        visible(val){
            if(val){
                if(this.data){
                    this.title = "编辑轮播图"
                    this.url = this.data.url
                    this.imageUrl = this.data.img_url
                }else{
                    this.title = "新增轮播图"
                    this.imageUrl = ''
                    this.url = ''
                }
            }
        }
    },
    data(){
        return{
            confirmLoading: false,
            loading: false,
            title: '',
            imageUrl:'',
            formItemLayout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 18 },
            },
            url:''
        }
    },
    methods:{
        handleSubmit(){
            if(!this.imageUrl){
                this.$message.error('请上传图片')
                return
            }
            this.confirmLoading = true
            if(this.data){
                http.edit_banner({img_url: this.imageUrl,url: this.url, id:this.data.id}).then(res=>{
                    this.$emit('close')
                    this.$emit('update')
                    this.confirmLoading = false
                }).catch((error)=>{
                    console.log(error)
                    this.confirmLoading = false
                })
            }else{
                http.add_banner({img_url: this.imageUrl,url: this.url}).then(res=>{
                    this.$emit('close')
                    this.$emit('update')
                    this.confirmLoading = false
                }).catch((error)=>{
                    console.log(error)
                    this.confirmLoading = false
                })
            }
        },
        beforeUpload(){

        },
        customRequest(data){
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
                if(res){
                    this.imageUrl=res.fileUrl
                }
            },error=>{
             console.log(error)
            })
        },
        handleChange(){

        }
    }
}
</script>